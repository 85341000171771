

.GhostButton__GhostButton_2peJ {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

  .GhostButton__GhostButton_direcion_column_1Fcn .GhostButton__inner_1H7N {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
}

  .GhostButton__GhostButton_direcion_column_1Fcn .GhostButton__text_225r {
      margin-left: 0;
}

  .GhostButton__GhostButton_color_gray_2Hnw .GhostButton__inner_1H7N {
      color: rgba(0, 0, 0, 0.54);
}

  .GhostButton__GhostButton_color_gray_2Hnw:hover .GhostButton__inner_1H7N {
      color: rgba(0, 0, 0, 0.38);
}

  .GhostButton__GhostButton_disabled_2p7-,
  .GhostButton__GhostButton_disabled_2p7- * {
    pointer-events: none;
}

  .GhostButton__GhostButton_disabled_2p7- .GhostButton__icon_2UiU, .GhostButton__GhostButton_disabled_2p7- * .GhostButton__icon_2UiU {
      background-color: #CECECE;
}


.GhostButton__GhostButton_2peJ:hover {
  opacity: 0.8;
}


.GhostButton__GhostButton_2peJ:focus {
  outline: 5px auto rgb(77, 144, 254);
}


.GhostButton__inner_1H7N {
  position: relative;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
  cursor: inherit;
}


.GhostButton__icon_2UiU {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 32px;
  height: 32px;
  color: #fff;
  cursor: inherit;
  background-color: #c4d600;
  border-radius: 50%;
}


.GhostButton__icon_color_red_o8yO {
    background-color: #d22630;
}


.GhostButton__icon_size_big_1d9W {
    width: 32px;
    height: 32px;
}


.GhostButton__icon_size_small_2yb6 {
    width: 24px;
    height: 24px;
}


.GhostButton__icon_style_inline_3y7h {
    width: auto;
    height: auto;
    color: currentColor;
    cursor: pointer;
    background-color: transparent;
    border-radius: 0;
}


.GhostButton__icon_style_inline_3y7h + .GhostButton__text_225r {
      margin-top: 2px;
}


@media (max-width: 767px) {


.GhostButton__icon_style_inline_3y7h + .GhostButton__text_225r {
        margin-top: 1px;
        font-size: 12px;
        line-height: 20px;
  }
}


@media (max-width: 767px) {


.GhostButton__icon_style_inline_3y7h {
      width: 15px;
      height: 14px;
  }
}


.GhostButton__text_225r {
  display: block;
  margin-top: 2px;
  margin-left: 8px;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: inherit;
}
