

.FailedToLoad__FailedToLoad_2hil {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 144px;
  padding-bottom: 144px;
  background-color: #EDEDED;
}

  @media (max-width: 767px) {

.FailedToLoad__FailedToLoad_2hil {
    padding-top: 78px;
    padding-bottom: 64px;
    background-color: #f5f5f5;
  }
}

.FailedToLoad__transparentBackground_39Wm {
  background-color: transparent;
}

@media (max-width: 767px) {

.FailedToLoad__transparentBackground_39Wm {
    background-color: transparent;
  }
}

.FailedToLoad__top_1csc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
  text-align: center;
}

@media (max-width: 767px) {

.FailedToLoad__top_1csc {
    width: 250px;
    font-size: 14px;
    line-height: 20px;
  }
}
